














































































































































































































































































































































































import { Component, Vue, Prop } from "vue-property-decorator";
import { GetMaiDetail2 } from "@/request/research";
import { GetPatientData } from "@/request/differences";
@Component({})
export default class Name extends Vue {
  private id: any = "";
  private patient: any = {};
  private source_type: any = "";
  private detail: any = {
    专家建议: {
      按诊断分: {
        中医疾病: [],
        中医证型: [],
        西医疾病: [],
      },
    },
  };
  private getText(key: any, type: any) {
    let str: any = "";
    const d = this.detail[key];
    const keys: any = {
      定性参数: [
        {
          name: "脉位",
          label: "脉位",
          type: "单选",
        },
        {
          name: "脉节律",
          label: "脉节律",
          type: "单选",
        },
        {
          name: "脉力",
          label: "脉力",
          type: "单选",
        },
        {
          name: "紧张度",
          label: "紧张度",
          type: "单选",
        },
        {
          name: "流利度",
          label: "流利度",
          type: "单选",
        },
        {
          name: "脉率",
          label: "脉率",
          type: "单选",
        },
        {
          name: "脉名",
          label: "脉名",
          type: "单选",
        },
      ],
      定量参数: [
        {
          name: "t1",
          label: "t1(s)",
          type: "单选",
        },
        {
          name: "t2",
          label: "t2(s)",
          type: "单选",
        },
        {
          name: "t3",
          label: "t3(s)",
          type: "单选",
        },
        {
          name: "t4",
          label: "t4(s)",
          type: "单选",
        },
        {
          name: "t5",
          label: "t5(s)",
          type: "单选",
        },
        {
          name: "h3/h1",
          label: "h3/h1",
          type: "单选",
        },
        {
          name: "h5/h1",
          label: "h5/h1",
          type: "单选",
        },
        {
          name: "w1/t",
          label: "w1/t",
          type: "单选",
        },
        {
          name: "w1",
          label: "w1",
          type: "单选",
        },
        {
          name: "h1",
          label: "h1(g)",
          type: "单选",
        },
        {
          name: "h2",
          label: "h2(g)",
          type: "单选",
        },
        {
          name: "h3",
          label: "h3(g)",
          type: "单选",
        },
        {
          name: "h4",
          label: "h4(g)",
          type: "单选",
        },
        {
          name: "h5",
          label: "h5(g)",
          type: "单选",
        },
        {
          name: "h4/h1",
          label: "h4/h1",
          type: "单选",
        },

        {
          name: "t1/t",
          label: "t1/t",
          type: "单选",
        },
        {
          name: "w2/t",
          label: "w2/t",
          type: "单选",
        },
        {
          name: "w2",
          label: "w2",
          type: "单选",
        },
        {
          name: "As",
          label: "As(g*s)",
          type: "单选",
        },
        {
          name: "Ad",
          label: "Ad(g*s)",
          type: "单选",
        },
        {
          name: "A",
          label: "A(g*s)",
          type: "单选",
        },
        {
          name: "t",
          label: "t(s)",
          type: "单选",
        },
        {
          name: "t5/t4",
          label: "t5/t4",
          type: "单选",
        },
      ],
    };
    keys[type].forEach((element: any) => {
      if (d && d[element.name]) {
        if (element.type == "单选" && d[element.name]) {
          str += element.label + "（" + d[element.name] + "）；";
        } else if (
          element.type == "多选" &&
          d[element.name] &&
          d[element.name].length > 0
        ) {
          const text: any = d[element.name].join("、");
          str += element.label + "（" + text + "）；";
        }
      }
    });
    return str ? type + "：" + str : type + "：" + "/";
  }
  private getDetail() {
    const params: any = {
      params: {
        pulse_id: this.id,
        source_type: this.source_type,
      },
    };
    GetMaiDetail2(this, params)
      .then((data: any) => {
        this.detail = data;
      })
      .catch(() => {});
  }
  private mounted() {
    this.id = this.$route.query.id;
    this.source_type = this.$route.query.source_type;
    this.getDetail();
  }
}
